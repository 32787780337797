<template>
    <div class="popup-overlay" v-show="popupVisible">
        <div class="popup-backdrop" @click="toggleCloseBtn"></div>

        <div class="popup-content">
            <span class="close-btn" @click="toggleCloseBtn">
                <svg class="thin-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path
                    d="M18.36 6.64a1 1 0 0 0-1.41 0L12 11.59 7.05 6.64a1 1 0 0 0-1.41 1.41L10.59 13l-4.95 4.95a1 1 0 0 0 1.41 1.41L12 14.41l4.95 4.95a1 1 0 0 0 1.41-1.41L13.41 13l4.95-4.95a1 1 0 0 0 0-1.41z" />
                </svg>
            </span>

            <img class="pop-up-content" src="../assets/images/popup-message/lbp-popup-banner-mobile.jpg" alt="Laser Teaser">
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            popupVisible: true
        }
    },
    methods: {
        toggleCloseBtn() {
            this.popupVisible = !this.popupVisible;
        }
    }
}
</script>

<style scoped>
    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 50;
    }

    .popup-backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
        cursor: pointer;
    }

    .popup-content {
        position: relative;
        width: 280px;
        height: auto;
    }

    .close-btn {
        position: absolute;
        top: -37px;
        right: -20px;
        cursor: pointer;
        z-index: 1;
    }

    .close-btn:hover::before {
        content: "";
        position: absolute;
        inset: -3px;
        background-color: #36454F;
        border-radius: 50%;
        z-index: -1;
    }

    .thin-icon {
        width: 30px;
        height: 30px;
        fill: #818589;
        position: relative;
        z-index: 2;
    }

    @media (min-width: 425px) {
        .popup-content {
            width: 360px;
        }
    }
    
    @media (min-width: 768px) {
        .popup-content {
            width: 350px;
        }
    }
    
    @media (min-width: 1024px) {
        .close-btn {
            right: -35px;
        }

        .popup-content {
            width: 500px;
            margin-top: 56px;
        }
    }
    
    @media (min-width: 1280px) {
        .popup-content {
            width: 500px;
            margin-top: 100px;
        }
    }

    @media (min-width: 1536px) {
        .popup-content {
            width: 600px;
        }
    }
</style>