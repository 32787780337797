<template>
  <div class="relative">
    <Nav class="z-50" />
    <div class="w-screen section-container laybare-banner space-top">
      <div class="relative h-full w-full flex items-start justify-center flex-col">
        <img src="../assets/images/mobile-assets/landing-page/landing-lb-cropped.png" alt="Lay Bare Banner"
          class="absolute top-0 right-0 h-full object-cover z-5 -mr-10 mobileL:-mr-0">
        <div class="flex items-start mobileL:items-center flex-col z-10 max-w-md xl:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-8 text-center 
          mobileL:ml-0 ml-7 xl:ml-16 2xl:ml-20 gap-4 mobileL:gap-8 xl:gap-1">
          <img class="flex h-32 w-auto mobileL:w-44 mobileL:h-auto lg:w-40 xl:w-60 2xl:w-80 mx-auto" src="../assets/images/home-page/header/logo2.png" alt="Lay bare logo">
          <p class="hidden mobileL:block text-base xl:text-xl 2xl:text-3xl text-white-1 ">
            We believe that having smooth, hair-free skin is the first step
            toward your best self. With Lay Bare, we've elevated the experience -
            making it affordable, accessible and more comfortable.
          </p>
          <button
            class="redirect-laybare-btn font-semibold bg-brown-3 rounded-full text-white-1 px-4 pt-4 pb-1 text-md xl:text-base 2xl:text-xl flex gap-2 xl:gap-3 2xl:gap-4"
            @click="$router.push('/laybare')">
              Go to Lay Bare<span class="fas fa-arrow-right"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="w-screen section-container laybare-plus-banner">
      <div class="relative h-full w-full flex items-end justify-center flex-col">
        <img src="../assets/images/mobile-assets/landing-page/landing-lbplus-cropped.png" alt="Lay Bare Plus banner"
          class="absolute top-0 left-0 h-full object-cover z-5 -ml-20 mobileL:-ml-0">

        <div class="flex items-end mobileL:items-center flex-col z-10 max-w-md xl:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-8 text-center 
          mr-6 mobileL:ml-0 xl:ml-16 2xl:ml-20 gap-4 mobileL:gap-8 mt-12 xl:gap-1">
          <img class="flex h-32 w-auto mobileL:w-44 mobileL:h-auto xl:w-60 2xl:w-80 mx-auto" src="../assets/images/home-page/Lay-Bare-Plus.png" alt="Lay bare Plus logo">
          <p class="hidden mobileL:block text-base xl:text-xl 2xl:text-3xl mt-4 text-white-1">
            Walk in and strut out. Lay Bare Plus is
            your destination for all things waxing, skin care and brows! With our
            elite skin treatments, you'll leave feeling refreshed and
            confident!
          </p>
          <button class="redirect-laybareplus-btn font-semibold bg-green-8 rounded-full text-white-1 px-4 pt-4 pb-1 text-base 2xl:text-xl flex gap-2 xl:gap-3 2xl:gap-4"
          @click="$router.push('/laybareplus')">
            Go to Lay Bare Plus <span class="fas fa-arrow-right"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="w-screen section-container passionails-banner">
      <div class="relative h-full w-full flex items-start justify-center flex-col">
        <img src="../assets/images/mobile-assets/landing-page/pn-banner.png" alt="Passionails banner"
          class="absolute top-0 right-0 pb-2 xl:pb-4 h-full object-cover z-5 -mr-20">
        <div class="flex items-start mobileL:items-center flex-col z-10 max-w-md xl:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-8 text-center mobileL:ml-0 ml-8 xl:ml-16 2xl:ml-20 gap-4 mobileL:gap-8 mt-12 xl:gap-1">
          <img class="flex h-32 w-auto mobileL:w-44 mobileL:h-auto xl:w-60 2xl:w-80 mx-auto" src="../assets/images/home-page/passionails-logo.png" alt="Passionails logo">
          <p class="hidden mobileL:block text-base xl:text-xl 2xl:text-3xl mt-4 mb-2 text-white-1">
            At Passionails, we've curated our
            services to deliver the best nail treatments in the country! From our
            basic nail cleaning to our relaxing hand and foot massages, there really
            is one for everyone.
          </p>
          <button class="redirect-passionails-btn font-semibold rounded-full text-white-1 px-4 pt-4 pb-1 text-base 2xl:text-xl flex gap-2 xl:gap-3 2xl:gap-4" 
            @click="$router.push('/passionails')">
            Go to Passionails <span class="fas fa-arrow-right"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="w-screen section-container lavish-banner">
      <div class="relative h-full w-full flex items-end justify-center flex-col">
        <img src="../assets/images/mobile-assets/landing-page/landing-lavish.png" alt="Lavish Lashes Banner"
          class="absolute top-0 left-0 h-full z-5 -ml-40 object-cover overflow-x-visible mobileL:-ml-64 lg:-ml-36 xl:ml-0">
        <div class="flex items-start mobileL:items-center flex-col z-10 max-w-md xl:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-8 text-center 
          mr-8 mobileL:ml-0 xl:ml-16 2xl:ml-20 gap-4 mobileL:gap-8 mt-6 xl:gap-1">
          <img class="flex h-32 w-auto mobileL:w-44 mobileL:h-auto xl:w-60 2xl:w-80 mx-auto" src="../assets/images/home-page/lavishlashes-logo.png" alt="Lay bare Plus logo">
          <p class="hidden mobileL:block text-base xl:text-xl 2xl:text-3xl xl:mt-6 text-white-1">
            Say hello to the best lashes you've ever had!
          </p>  
          <p class="hidden mobileL:block text-base xl:text-xl 2xl:text-3xl xl:mt-4 xl:mb-4 text-white-1">
            At Lavish Lashes Studio, we specialize in comfortable
            yet reasonable eyelash services, expertly crafted With
            our signature silk lashes.
          </p>
          <button class="redirect-lavish-btn font-semibold rounded-full text-white-1 mobileL:px-8 pt-3 px-4 mobileL:pt-4 pb-2 
          text-base 2xl:text-xl flex gap-2 xl:gap-3 2xl:gap-4" @click="$router.push('/lavish-lashes')">
            Go to Lavish Lashes Studio <span class="fas fa-arrow-right ml-2"></span>
          </button>
        </div>
      </div>
    </div>
    <PopUpModal />
  </div> 
</template>

<script>
import Nav from '../components/navigation/LaybareHeader.vue'
import PopUpModal from '../components/PopUpModal.vue';
export default {
  components: {
    Nav,
    PopUpModal
  },
}
</script>

<style scoped>
.section-container {
  height: 700px;
}

.space-top {
  margin-top: 90px;
}

@media (max-width: 480px) {
  .space-top {
    margin-top: 60px;
  }
  .section-container {
    height: 46.5vh;
    overflow: hidden;
  }
}

.laybare-banner {
  background: linear-gradient(180deg, #F2D0B7 0%, #D9AC8D 100%);
  font-family: 'Kannada';
}

.redirect-laybare-btn {
  box-shadow: 0px 4px 0px 0px #775C35;
  height: 50px;
}

.laybare-plus-banner {
  background: linear-gradient(180deg, #4A8E7F 0%, #336D5F 100%);
  font-family: 'Kannada';
}

.redirect-laybareplus-btn {
  box-shadow: 0px 4px 0px 0px #1B3C33;
  height: 50px;
}

.redirect-lavish-btn {
  background-color: #C45967;
  box-shadow: 0px 4px 0px 0px #974A57;
}

.passionails-banner {
  background: linear-gradient(180deg, #C98195 0%, #D4A3B4 100%);
  font-family: 'Kannada';
}

.redirect-passionails-btn {
  background: #905C69;
  box-shadow: 0px 4px 0px 0px #724953;
  height: 50px;
}

.lavish-banner {
  background: url('../assets/images/mobile-assets/landing-page/landing-lavish-bg.jpg') no-repeat;
  background-size: 100% 100%;
  font-family: 'Kannada';
}

.lavish-coming-soon-banner {
  background-color: #AD0F58;
  font-family: 'Rage Italic';
}

@media (min-width: 1280px) {
    .space-top {
      margin-top: 100px;
    }
    .section-container {
      height: calc(120vh - 100px);
    } 
}

@media (min-width: 1536px) {
    .space-top {
      margin-top: 128px;
    }

    .section-container {
      height: 110vh;
    }
}
</style>